import styled from 'styled-components';


export const FooterContent = styled.div`
    display: flex;
    width: 100%;
    padding: 30px 0px;
    bottom: 0;
    left: 0;
`;

export const FooterText = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        font-size: 15px;
        font-weight: 300;
    }
`;