import React from 'react';
import Experience from './Experience';
import * as Ex from './Experience.styled';

const Experiences = () => {

    const experiencesData = [
        {
            id: 1,
            year: "2022 - Present",
            degree: "SFMC Developer",
            content: "Designing, implementing, and customizing solutions in Salesforce Marketing Cloud using programming languages, API integrations, and cloud technologies to deliver personalized and automated marketing experiences for clients.",
        },
        {
            id: 2,
            year: "2021 - 2023",
            degree: "Technical Marketing Automation",
            content: "Configuration of Salesforce Marketing Cloud and analysis and optimization of marketing activities.",
        },
        {
            id: 3,
            year: "2019 - 2021",
            degree: "Marketing Automation Specialist",
            content: "Designing, implementing, and optimizing marketing processes using Salesforce Marketing Cloud.",
        },
        {
            id: 4,
            year: "2017 - 2019",
            degree: "Offer Specialist",
            content: "Creating detailed product descriptions that comply with SEO standards.",
        },
    ]

    return(
        <Ex.ExperienceContent>
            <Ex.ExperienceRow>
                    {experiencesData.map((experience) => (
                    <Experience 
                        experience={experience} 
                        key={experience.id} 
                    />
                ))}
                <Ex.ExperienceTimelineSpan></Ex.ExperienceTimelineSpan>
            </Ex.ExperienceRow>
        </Ex.ExperienceContent>
    )
}

export default Experiences;