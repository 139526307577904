import styled from 'styled-components'

export const Content = styled.div`
    display: flex;
    width: 100%;
    padding: 60px 0px;
`

export const UserNavigation = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 0.5;
    justify-content: center;
`;

export const UserSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 2;
    justify-content: flex-start;
    margin-left: 50px;
`