import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import WelcomeUser from './WelcomeUser';


const AccountLink = styled(Link)`
    color: #fff;
    text-decoration: none;
    align-items: center;
    padding: 8px 10px;
    transition: margin-right 2s ease-in-out .5s;
    font-weight: 300;
    cursor: pointer;

    &:hover {
      border-bottom: 1px solid #fedd2b;
      color: #fedd2b;
      transition: 0.3s;
    }
`;


const NaviAccount = () => {

    const location = useLocation();

    return (
        <>
            <AccountLink to='/user'>
                {location.pathname === '/user' ? 
                    <MsalAuthenticationTemplate interactionType={InteractionType.Popup}><WelcomeUser /></MsalAuthenticationTemplate> 
                : 
                    'account'}
            </AccountLink>
        </>
    )
};

export default NaviAccount;