import React from 'react';
import * as Fo from './Footer.styled';

const Footer = () => {
    return (
        <Fo.FooterContent>
            <Fo.FooterText>
                <span>© DeGree 2023</span>
            </Fo.FooterText>
        </Fo.FooterContent>
    )
};

export default Footer