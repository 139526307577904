import React from 'react';
import styled from 'styled-components';

const SkItem = styled.div`
    display: flex;
    flex-direction: column;
`;

const SkInfo = styled.div`
    display: flex;
    flex-direction: column;

    h4 {
        font-size: 17px;
        font-weight: 300;
        margin: 0px 0px 20px 0px;
    }
    
    span {
        font-size: 14px;
        font-weight: 500;
    }
`;

const SkProgress = styled.div`
    position: relative;
    display: block;
    width: 100%;
    height: 6px;
    background-color: #e0e0e0;
    border-radius: 6px;
    overflow: hidden;
`;

const Skill = ({progress: { name, percentage }, isVisible }) => {
    const winWidth = window.innerWidth;

    const progressQuery = () => {
        if(winWidth && winWidth > 767) {
            return (
                <div
                    className="progress-bar" 
                    role="progressbar" 
                    aria-valuemin="0" 
                    aria-valuemax="100" 
                    aria-valuenow={percentage}
                    style={
                        isVisible
                        ? {
                            width: `${percentage}%`,
                        }
                        : {
                            width: 0,
                        }
                    }
                ></div>
            )
        }
        return (
            <div 
                className="progress-bar" 
                role="progressbar" 
                aria-valuemin="0" 
                aria-valuemax="100" 
                aria-valuenow={percentage}
                style={{
                    width: `${percentage}%`
                }}   
            > </div>
        )
    }
    return (
        <SkItem>
            <SkInfo>
                <h4>{name}</h4>
                <span>
                    {percentage}%
                </span>
            </SkInfo>
            <SkProgress>
                {progressQuery()}
            </SkProgress>
        </SkItem>
    )
}

export default Skill;