import React from 'react';
import * as P from './parts';


const UserNavigation = ({ selectedNavItem, onNavItemClick }) => {
    return (
        <P.NavigationUser>
            <P.NavigationContainer>
                <P.NavLink
                    onClick={() => onNavItemClick('settings')}
                    active={selectedNavItem === 'settings'}
                >
                    <P.IconCi />Settings
                </P.NavLink>
                <P.NavLink
                    onClick={() => onNavItemClick('documentations')}
                    active={selectedNavItem === 'documentations'}
                >
                    <P.IconHi />Documentations
                </P.NavLink>
                <P.NavLink
                    onClick={() => onNavItemClick('support')}
                    active={selectedNavItem === 'support'}
                >
                    <P.IconBi />Support
                </P.NavLink>
                <P.NavLink
                    onClick={() => onNavItemClick('subscriptions')}
                    active={selectedNavItem === 'subscriptions'}
                >
                    <P.IconTb />Subscriptions
                </P.NavLink>
                <P.NavLink
                    onClick={() => onNavItemClick('logout')}
                    active={selectedNavItem === 'logout'}
                >
                    <P.IconAi />Logout
                </P.NavLink>
            </P.NavigationContainer>
        </P.NavigationUser>
    )
};

export default UserNavigation;