import styled from 'styled-components';

export const Mmenu = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 50%;
    padding: 30px 0px;
    border-bottom: 1px solid #909090;
`;