import React, { useState } from 'react';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import UserNavigation from '../../components/account/UserNavigation/UserNavigation';
import * as P from './parts'
import UserSettings from '../../components/account/UserSection/UserSettings/UserSettings';
import UserDocumentations from '../../components/account/UserSection/UserDocumentations/UserDocumentations';
import styled from 'styled-components'
import UserSupport from '../../components/account/UserSection/UserSupport/UserSupport';
import UserSubscriptions from '../../components/account/UserSection/UserSubscriptions/UserSubscriptions';


const Section = styled.div`
  flex: 1;
  padding: 20px;
`;

const UserPanel = () => {

    const [selectedNavItem, setSelectedNavItem] = useState('settings');

    const handleNavItemClick = (navItem) => {
        setSelectedNavItem(navItem);
    };

    const renderSection = () => {
        switch (selectedNavItem) {
            case 'settings' 
                :
                return <UserSettings />;
            case 'documentations' 
                :
                return <UserDocumentations />;
            case 'support'
                :
                return <UserSupport />;
            case 'subscriptions'
                :
                return <UserSubscriptions />;
            default
                :
                return null;

        }
    };

    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Popup}>
            <P.Content>
                <P.UserNavigation>
                    <UserNavigation 
                        selectedNavItem={selectedNavItem}
                        onNavItemClick={handleNavItemClick}
                    />
                </P.UserNavigation>
                <P.UserSection>
                    <Section>{renderSection()}</Section>
                </P.UserSection>
            </P.Content>
        </MsalAuthenticationTemplate>
    )
}

export default UserPanel;