import React from 'react';
import './services.css';
import mcImg from '../../assets/img/marketing-cloud.png';
import { FaSalesforce } from 'react-icons/fa';
import { HiOutlineHashtag } from 'react-icons/hi';
import styled from 'styled-components';
import * as A from '../../styles';
import * as Se from './Services.styled';


const Icons = styled.div`
    font-size: 16px;
    color: #ffc732;
    margin-right: 5px;
    display: flex;
    margin-top: 2px;
`;

const Services = () => {
    return (
        <A.ContentCenter id="services">
            <A.TitleComponent>
                <A.TitleComponentP>
                    services
                </A.TitleComponentP>
            </A.TitleComponent>
            <Se.SRow>
                <Se.ServicesLeft>
                    <Se.ServicesBg>
                        <Se.ServicesRow>
                            <Se.ServicesHeader>
                                <FaSalesforce />
                            </Se.ServicesHeader>
                            <Se.ServicesDesc>
                                <h4>Salesforce Marketing Cloud</h4>
                                <Se.ServicesListItem>
                                    <li>
                                        <Icons>
                                            <HiOutlineHashtag/>
                                        </Icons>
                                        Programming and configuration of Salesforce Marketing Cloud using languages such as AMPscript, HTML, CSS, and JavaScript.
                                    </li>
                                    <li>
                                        <Icons>
                                            <HiOutlineHashtag/>
                                        </Icons>
                                        Creation of advanced marketing campaigns, including automated processes and customer segmentation.
                                    </li>
                                    <li>
                                        <Icons>
                                            <HiOutlineHashtag/>
                                        </Icons>
                                        Integration of Salesforce Marketing Cloud with other tools and platforms.
                                    </li>
                                    <li>
                                        <Icons>
                                            <HiOutlineHashtag/>
                                        </Icons>
                                        Deployment and testing of new functionalities on the Salesforce Marketing Cloud platform.
                                    </li>
                                </Se.ServicesListItem>   
                            </Se.ServicesDesc>
                        </Se.ServicesRow>
                    </Se.ServicesBg>
                </Se.ServicesLeft>
                <Se.ServicesRight>
                    <Se.ServicesRightImg>
                        <img src={mcImg} alt="services"/>
                    </Se.ServicesRightImg>
                </Se.ServicesRight>
            </Se.SRow>
        </A.ContentCenter>
    )
}

export default Services;