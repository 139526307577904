import React from 'react';
import Intro from '../../components/intro/Intro';
import About from '../../components/about/About';
import Services from '../../components/services/Services';
import '../../assets/styles/app.css';
import * as A from '../../styles'
import Footer from '../../components/footer/Footer';

const HomePage = () => {
    return (
        <A.Container>
            <A.Section>
                <Intro />
                <About />
                <Services />
            </A.Section>
        </A.Container>
    )
};

export default HomePage;