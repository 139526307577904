import React from 'react';
import * as Ex from './Experience.styled';


const Experience = ({experience: {year, degree, content}}) => {

    return (
        <Ex.ExperienceDesc>
            <Ex.ExperienceTitle>
                <span>{year}</span>
            </Ex.ExperienceTitle>
            <Ex.ExperienceBody>
                <h4>{degree}</h4>
                <p>{content}</p>
            </Ex.ExperienceBody>
        </Ex.ExperienceDesc>
    )
}

export default Experience;