import styled from 'styled-components';

export const SRow = styled.div`
    display: flex;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        width: 100%;
        display: flex;
    }
`;

export const ServicesLeft = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1.5;
    justify-content: center;
`;

export const ServicesBg = styled.div`
    background: #2e2e2e;
    width: 100%;
    border-radius: 20px;
    display: flex;
`;

export const ServicesRow = styled.div`
    display: flex;
    padding: 50px 40px;

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 30px 15px;
    }

`;

export const ServicesHeader = styled.div`
    font-size: 66px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: 0px;
    flex: 1 1;
    flex-direction: column;
`;

export const ServicesDesc = styled.div`
    display: flex;
    flex: 8 1;
    flex-direction: column;
    
    h4 {
        margin: 0;
        padding: 0;
        font-size: 21px;
        font-weight: 500;

        @media (max-width: 768px) {
            margin-top: 20px;
        }
    }
`;

export const ServicesListItem = styled.ul`
    margin: 0;
    font-size: 15px;
    font-weight: 300;
    display: flex;
    padding: 20px 0px 0px 0px;
    flex-direction: column;

    li {
        width: 100%;
        padding: 10px 0px;
        display: flex;
    }
`;

export const ServicesRight = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    justify-content: center;

    @media (max-width: 768px) {
        margin-bottom: 30px;
    }
`;

export const ServicesRightImg = styled.div`
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 50%;
        object-fit: cover;

        @media (max-width: 768px) {
            width: 75%;
        }
    }
`;