import React from 'react';
import Skill from './Skill';
import TrackVisibility from 'react-on-screen';

import styled from 'styled-components';

const SkContent = styled.div`
    display: flex;
    width: 90%;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const SkRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
`;

const SkProgress = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(45.33% - 10px);
    justify-content: center;
    padding: 30px 20px 30px 0px;

    @media (max-width: 768px) {
        padding: 30px 0px 30px 0px;
    }
`;
const Experience = () => {

    const skillData = {
        progressData: [
            {
                id: 1,
                name: "HTML & CSS",
                percentage: 90,
            },
            {
                id: 2,
                name: "JavaScript",
                percentage: 75,
            },
            {
                id: 3,
                name: "AMPScript",
                percentage: 85,
            },
            {
                id: 4,
                name: "React",
                percentage: 55,
            },
            {
                id: 5,
                name: "Salesforce MC",
                percentage: 80,
            },
            {
                id: 6,
                name: "Photoshop",
                percentage: 70,
            },
        ]
    }

    return (
        <SkContent>
            <SkRow>
                {skillData.progressData.map((progress) => (
                    <SkProgress key={progress.id}>
                        <TrackVisibility>
                        <Skill progress={progress} />
                        </TrackVisibility>
                    </SkProgress>
                ))}
            </SkRow>
        </SkContent>
    )
}

export default Experience;