import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from "react-scroll";
import './navbar.css';
import ModalMenu from '../../components/modal/ModalMenu';
import { AiOutlineMenu } from 'react-icons/ai';
import * as N from './NavbarStyles';
import styled from 'styled-components';
import NaviAccount from '../account/NaviAccount';

const StyledMenu = styled(Link)`
    color: #fff;
    text-decoration: none;
    align-items: center;
    padding: 8px 10px;
    transition: margin-right 2s ease-in-out .5s;
    font-weight: 300;
    cursor: pointer;

    &:hover {
      border-bottom: 1px solid #fedd2b;
      color: #fedd2b;
      transition: 0.3s;
    }
`;

const Navi = () => {

  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };
  
  const handleClose = () => {
    setShow(false);
  };

const location = useLocation();

  return (
    <>
      <nav>
        <N.NaviB>
          {location.pathname === '/' && (
            <N.NavItem>
              <StyledMenu
                to="about" 
                smooth={true} 
                duration={500} 
                offset={-90}
                spy={true}
              >
                about me
              </StyledMenu>
            </N.NavItem>
          )}
          {location.pathname === '/' && (
            <N.NavItem>
              <StyledMenu 
                to="services" 
                smooth={true} 
                duration={500} 
                offset={-70}
                spy={true}
              >
                services
              </StyledMenu>
            </N.NavItem>
          )}
          {location.pathname === '/' && (
          <N.NavItem>
            <StyledMenu 
              to="contact" 
              smooth={true} 
              duration={500} 
              offset={-70} 
              spy={true}
            >
              contact
            </StyledMenu>
          </N.NavItem>
          )}
        </N.NaviB>
        <N.ToggleMenuTop onClick={() => {
              if (show) {
                handleClose();
              } else {
                handleShow();
              }
            }}>
          <N.ToggleMenu>
            <AiOutlineMenu />
          </N.ToggleMenu>
        </N.ToggleMenuTop>
      </nav>
      <ModalMenu 
        show={show} 
        handleClose={handleClose}
      />
    </>
  )
}

export default Navi