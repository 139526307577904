import styled from 'styled-components';

export const Nav = styled.header`
    width: 100%;
    background: #272727;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 3;
    height: 80px;
    transition: 0.3s;
    box-shadow: rgb(27, 27, 27) 0px 2px 8px;
    animation: slideInFromTopS 0.3s ease-in-out;


    @keyframes slideInFromTopS {
    0% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(0%);
    }
};

    &.sticky {
        position: fixed;
        box-shadow: rgba(0, 0, 0, 0.64) 0px 2px 8px ;
        top:0px;
        transition: 0.5s;
        background: #272727;
        height: 80px;
        z-index: 3;
        animation: slideInFromTop 0.5s ease-in-out;
    };

    @keyframes slideInFromTop {
    0% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(0);
    }
};

    @media (min-width: 900px) {
        position: relative;
        top: 0;
    };

    @media (min-width: 1080px) {
        overflow: visible;
    };
`;


export const Navbar = styled.div `
    display: flex;
    align-items: center;
    flex-flow: wrap;
    justify-content: space-between;
    height: 100%;

    @media (min-width: 1080px) {
        width: calc(100% - 100px);
        margin: 0 auto;
    }
`;

export const LogoNav = styled.div`
    display: flex;
    position: relative;
    flex-shrink: 0;
    align-items: center;
    height: 100%;
`;

export const MenuNav = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
`;

export const NavItem = styled.li`
    float: left;
    list-style: none;
    padding: 8px 20px;
`;

export const NaviB = styled.ul`
    display: flex;
    width: 100%;
    margin: 0;

    @media (max-width: 786px) {
        display: none;
    }
`;

export const ToggleMenuTop = styled.div`
    display: block;

    @media (max-width: 768px) {
        display: flex;
        position: fixed;
        width: 50px;
        height: 50px;
        top: 10px;
        right: 0;
        justify-content: center;
        align-items: center;
        background: #272727;
        border-top: 1px solid #909090;
        border-left: 1px solid #909090;
        border-bottom: 1px solid #909090;
        border-radius: 6px 0px 0px 6px;
    };
`;

export const ToggleMenu = styled.button`
    display: none;

    @media (max-width: 768px) {
        display: flex;
        background: none;
        border: 0px;
        padding: 0px;
        color: #fff;
        font-size: 21px;
    };
`;