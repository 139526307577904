import styled from 'styled-components';

export const AContent = styled.div`
    padding: 50px 0px 30px 0px;
    display: flex;
    flex-direction: column;  
`;


export const ARow = styled.div`
    padding: 50px 40px 50px 40px;
    display: flex;
    background: #2e2e2e;
    border-radius: 20px;

    @media (max-width: 768px) {
        padding: 30px 15px;
        flex-direction: column;
    };
`;

export const ADesc = styled.div`
    display: flex;
    width: 100%;
    
    @media (max-width: 768px) {
        flex-direction: column;
    };
`;

export const ALeft = styled.div`
    display: flex;
    flex: 1;
`;

export const ARight = styled.div`
    display: flex;
    flex: 1;
`;