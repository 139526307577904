import styled from 'styled-components';

export const ExperienceContent = styled.div`
    width: 100%;
    display: flex;

    @media (max-width: 768px) {
        margin-top: 40px;
    }
`;

export const ExperienceRow = styled.div`
    position: relative;
    padding: 50px 0px 0px 0px;
`;

export const ExperienceTimelineSpan = styled.span`
    position: absolute;
    top: 0;
    left: calc(20% + 2px);
    bottom: 0;
    width: 1px;
    background: #ffc732;
`;

export const ExperienceDesc = styled.div`
    clear: both;
    position: relative;
`;

export const ExperienceTitle = styled.div`
    float: left;
    width: 20%;
    position: relative;

    span {
        font-size: 16px;
        font-weight: 300;
    }

    &::before {
            content: '';
            position: absolute;
            width: 4px;
            height: 4px;
            border: 1px solid #ffc732;
            background-color: #ffc732;
            border-radius: 100%;
            padding: 4.5px;
            top: 15%;
            right: -10px;
            z-index: 1;
        }
    
`;

export const ExperienceBody = styled.div`
    float: right;
    width: 73%;
    margin-bottom: 30px;

    h4 {
        margin: 0;
        padding: 0;
        font-weight: 500;
        font-size: 17px;
    }

    p {
        margin: 0;
        font-weight: 300;
        font-size: 15px;
    }

`;

