import styled from 'styled-components';

export const Icontent = styled.div`
    display: flex;
    height: 80vh;

    @media (max-width: 1550px){
        margin-top: 20px;
    };

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        height: 100%;
    };
`;

export const ILeft = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

`;

export const ILeftSocialA = styled.a`
    margin-right: 30px;
    margin-bottom: 20px;
    padding: 10px;
    color: rgb(255, 255, 255);
    border: 1px solid rgb(144, 144, 144);
    border-radius: 5px;
    display: flex;

    &:Hover {
        color: #fedd2b;
        border: 1px solid #fedd2b;
        transition: 0.3s;
    };
`;

export const ILeftWrapper = styled.div`
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;  

    @media (max-width: 768px) {
        margin-top: 40px;
    };
`;

export const ILeftSocial = styled.div`
    display: flex;
    padding: 0px 0px;  
`;

export const ILeftHero = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;  
`;

export const IIntro = styled.h2`
    font-size: 30px;
    font-weight: 300;
    margin: 0px;
    padding:0px;

    @media (max-width: 768px) {
        font-size: 26px;
    }
`;

export const IName = styled.h2`
    margin: 0px;
    padding: 0px;
    font-size: 50px;
    line-height: 54px;  

    @media (max-width: 768px) {
        font-size: 44px;
    }
`;

export const ITitle = styled.div `
    height: 50px;
    overflow: hidden;
    margin-bottom: 20px;    
`;

export const ITitleWrapper = styled.div`
    height: 50px;
    font-size: 20px;
    font-weight: 500;
    color: #ffc732;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        font-size: 17px;
    }
`;

export const IDesc = styled.div`
    display: flex;
    border-top: 1px solid #909090;
    padding: 25px 0px;
    font-weight: 300;  
    font-size: 15px;

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

export const ICv = styled.div`
    display: flex;
    margin-top: 5px;
`;

export const IButton = styled.button`
    padding: 15px 30px;
    cursor: pointer;
    background: #ffc732;
    border: none;
    color: #000;
    border-radius: 8px;
    align-items: center;
    display: flex;
    transition: 0.3s;

    &:hover {
        background: #fdcc46;
        transition: 0.3s;
    }
`;

export const IRight = styled.div`
    flex: 1;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%; 
    
    @media (max-width: 768px) {
        margin-top: 40px;
    }
`;

export const IRightWrapper = styled.div`
    padding: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;


export const IRightImg = styled.img`
    width: 100%;
    height: 70%;
    object-fit: cover;
    border-radius: 53% 47% 61% 39% / 33% 57% 43% 67% ;
    animation: monkey 10s ease-in-out infinite; 
    
    @media (max-width: 768px) {
        width: 80%;
    }

`;